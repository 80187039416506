<template>
  <div class="user-management-container">
    <nxp-search
      :config-list="searchConfigList"
      @clickSearch="clickSearch"
      @resetForm="resetForm"
    />
    <!-- 表格 -->
    <select-table
      :accessories="nxpTabelAccessories"
      only-key-id="id"
      :table-columns="tableColumns"
      :table-data="tableData"
      :table-loading="tableLoading"
      :w-theight="320"
      @delSelect="delSelect"
      @fetchData="fetchData"
      @fetchTableSelection="fetchTableSelection"
    />
  </div>
</template>

<script>
import {
  queryCollectDataList,
  delCollectDataItem,
} from "@/project/eztree/baseApi/commonApi";
export default {
  name: "Logger",
  components: {},
  data() {
    return {
      queryForm: {},
      select_table_data: [],
      searchConfigList: [
        {
          type: "text",
          showName: false,
          model: "",
          param: "phone",
          label: "手机号",
        },
        {
          type: "daterange",
          showName: false,
          model: "",
          param: "date",
          label: "日期",
        },
      ],
      tableLoading: false,
      tableData: [],
      nxpTabelAccessories: {
        pageType: "",
        selectType: "",
        pageNo: 1,
        pageSize: 15,
        total: 0,
        height: this.$baseTableHeight(1),
      },
    };
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "手机号",
          prop: "phone",
        },
        { label: "ip", prop: "ip" },
        { label: "操作", prop: "action" },
        {
          label: "接口",
          prop: "request_uri",
          align: "left",
          tooltip: true,
        },
        {
          label: "次数",
          prop: "num",
          tooltip: true,
        },
        {
          label: "创建时间",
          prop: "created_at",
          width: "160px",
        },
        {
          label: "更新时间",
          prop: "last_active_at",
          width: "160px",
        },
      ];
      return columns;
    },
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {
    // 初始化
    async initQuery() {
      this.select_table_data = [];
      this.tableLoading = true;
      let params = Object.assign({}, this.queryForm);
      params.page = this.nxpTabelAccessories.pageNo;
      params.page_size = this.nxpTabelAccessories.pageSize;
      const { date } = params;
      if (date) {
        params.start_time = date[0];
        params.end_time = date[1];
        console.log("params", params, date);
        delete params.date;
      }
      queryCollectDataList(params).then(({ code, data }) => {
        if (code == "200") {
          this.tableData = (data && data.list) || [];
          this.nxpTabelAccessories.total = data.total_count;
        }
        this.tableLoading = false;
      });
    },
    // 分页查询
    fetchData(val) {
      this.select_table_data = [];
      this.nxpTabelAccessories.pageNo = val.pageNo;
      this.nxpTabelAccessories.pageSize = val.pageSize;
      this.initQuery();
    },
    // 模糊查询
    clickSearch(val) {
      this.queryForm = val;
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    resetForm() {
      this.queryForm = {};
      this.nxpTabelAccessories.pageNo = 1;
      this.initQuery();
    },
    handleEdit(row, action) {
      this.$refs["edit"].showEdit(row, action);
    },
    fetchTableSelection(data) {
      this.select_table_data = data;
    },
    delSelect(row, action) {
      let err_ids = this.select_table_data.map((ele) => {
        return ele.id;
      });
      delCollectDataItem({ err_ids }).then(({ code, data }) => {
        if (code == "200") {
          this.$baseMessage("删除成功", "success", "vab-hey-message-success");
          this.initQuery();
          this.btnChangeEnable = false;
        }
      });
    },
  },
};
</script>
